export class API {
    // Pour la recette Webnet
    // public qrCodeURL: string = 'http://swww.ocotec-avantage-mobile-int.sv.webnet.fr';
    // Pour la recette Socotec
    // public qrCodeURL: string = 'https://www.avantage-mobile-rct.socotec.com';
    // Pour la qualif Socotec
    // public qrCodeURL: string = 'https://www.avantage-mobile-qual.socotec.com';
    // Pour la prod Socotec
    public qrCodeURL: string = 'https://www.avantage-mobile.socotec.com';
    
    // Pour le localhost :
    public APIPATH: string = this.qrCodeURL + '/api/';

    public URLS: any = {
        authentification: this.APIPATH + 'Account/Token',
        mdpOublie: this.APIPATH + 'AccessManagement',
        elementsVerifiesURL: this.APIPATH + 'Elements',
        elementVerifieURL: this.APIPATH + 'Element',
        observationsURL: this.APIPATH + 'Observations',
        rapportsURL: this.APIPATH + 'Rapports',
        scanQRCodeURL: this.APIPATH + 'QRCode'
    }
}
